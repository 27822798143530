<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-2"
                >
                  <h6 class="mb-2">
                   Facturar A:
                  </h6>
                  <!-- Select Client -->
                  <v-select
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="razonSocial"
                    input-id="invoice-data-client"
                    :clearable="false"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Agregar nuevo Cliente</span>
                      </li>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.rfc }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.calle }}, {{ invoiceData.client.colonia }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.municipio }} {{ invoiceData.client.estado }} 
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.client.email }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">
                      Detalle del pago:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total:
                          </td>
                          <td><span class="font-weight-bold">$12,110.55</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Banco:
                          </td>
                          <td>Banorte</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Ciudad:
                          </td>
                          <td>United States</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            IBAN:
                          </td>
                          <td>ETD95476213874685</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            SWIFT code:
                          </td>
                          <td>BR91905</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-body class="invoice-padding form-item-section">
              <b-row>
                <b-col cols="12">
                  <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          Produto || Servicio
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          Costo
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cantidad
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Precio
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                  </div>
                   <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Servicio o Producto</label>
                          <v-select
                            v-model="itemSelected.nombre"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="nombre"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Seleccione un producto"
                            @input="val => setSelectedItem(val)"/>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Costo</label>
                          <b-form-input
                            v-model="itemSelected.concepto.ValorUnitario"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Cantidad</label>
                          <b-form-input
                            v-model="itemSelected.concepto.Cantidad"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Importe</label>
                          <p class="mb-1">
                            ${{ itemSelected.concepto.ValorUnitario * itemSelected.concepto.Cantidad }}
                          </p>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="5"
                        >
                          <label class="d-inline d-lg-none">Descripción</label>
                          <b-form-textarea
                            v-model="itemSelected.concepto.Descripcion"
                            class="mb-2 mb-lg-0"
                          />
                        </b-col>
                        <b-col lg="3" offset="4">
                          <p class="mb-0">
                            Importe: {{itemSelected.concepto.Importe}}
                          </p>
                          <p class="mb-0">
                            Descuento: {{itemSelected.concepto.Descuento}}
                          </p>
                          <p class="mb-0">
                            IVA: 
                          </p>
                        </b-col>
                        
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon"
                          @click="removeItem(itemSelected)"
                        >
                          <feather-icon icon="XIcon" size="18"/>
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success"
                            class="btn-icon"
                          >
                            <feather-icon icon="PlusIcon" size="18"/>
                        </b-button>
                        <b-button
                           :id="`form-item-settings-icon-first`"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="secondary"
                            class="btn-icon"
                          >
                          <feather-icon icon="SettingsIcon" size="18"/>
                        </b-button>
                        <!-- Setting Item Form -->
                        <b-popover
                          :ref="`form-item-settings-popover-first`"
                          :target="`form-item-settings-icon-first`"
                          triggers="click"
                          placement="lefttop"
                        >
                          <b-form @submit.prevent>
                            <b-row>
                              <!-- Field: Discount -->
                              <b-col cols="12">
                                <b-form-group
                                  label="Descuento"
                                  :label-for="`setting-item-first-discount`"
                                >
                                  <b-form-input
                                    :id="`setting-item-first-discount`"
                                    :value="null"
                                    type="number"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 1 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="IVA"
                                  :label-for="`setting-item-first-tax-1`"
                                >
                                  <v-select
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    v-model="itemSelected.status"
                                    :options="['0%','4%', '8%', '16%']"
                                    :input-id="`setting-item-first-tax-1`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <!-- Field: Tax 2 -->
                              <b-col cols="6">
                                <b-form-group
                                  label="IEPS"
                                  :label-for="`setting-item-first-tax-2`"
                                >
                                  <v-select
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :value="'0%'"
                                    :options="['0%', '1%', '10%', '14%', '18%']"
                                    :input-id="`setting-item-first-tax-2`"
                                    :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>

                              <b-col
                                cols="12"
                                class="d-flex justify-content-between mt-1"
                              >
                                 <b-button
                                  variant="outline-primary"
                                  @click="() => {$refs[`form-item-settings-popover-first`].$emit('close')}"
                                >
                                  Apply
                                </b-button>
                                <b-button
                                  variant="outline-secondary"
                                  @click="() => {$refs[`form-item-settings-popover-first`].$emit('close')}"
                                >
                                  Cancel
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-form>
                        </b-popover>
                      </div>
                    </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div class="items">
                <!-- Invoice Description: Table -->
                <b-table-lite
                  responsive
                  :items="invoiceData.items"
                  :fields="['descripcion', 'cantidad', 'precio', 'total']"
                >
                  <template #cell(descripcion)="data">
                    <b-card-text class="font-weight-bold mb-25">
                      {{ data.item.nombre }}
                    </b-card-text>
                    <b-card-text class="text-nowrap">
                      {{ data.item.descripcion }}
                    </b-card-text>
                  </template>
                </b-table-lite>
              </div>
            </b-card-body>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >

                <span class="font-weight-bold">Nota: </span>
                <b-form-textarea v-model="invoiceData.note" />
                </b-col>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2">
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $1800
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Descuento:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        IVA:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $1690
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
      <b-card>
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $1800
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Descuento:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        IVA:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $1690
                      </p>
                    </div>
                  </div>
      </b-card>
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            disabled
          >
            Enviar Factura
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Vista Previa
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
          >
            Timbrar
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, 
  BCardBody, 
  BButton, 
  BCardText, BForm, BFormGroup, 
  BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, 
  BFormCheckbox, BPopover, VBToggle, BTableLite,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BTableLite,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarAddNewCustomer,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {

      /*this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })*/

    },
    addNewItem(item){
      
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
     // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {

      /*this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })*/

    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    const itemsOptions = ref([])
    

    store.dispatch('app-invoice/fetchClients')
      .then(response => { clients.value = response.items })

    store.dispatch('app-invoice/fetchProducts')
      .then(response => { itemsOptions.value = response.items })

    const itemFormBlankItem = {
      item: null,
      costo: 0,
      qty: 0,
      nombre:'',
      descripcion: '',
    }
    const conceptoBlank = {
                    "concepto":{
                        "ClaveProdServ": '',
                        "NoIdentificacion": '',
                        "Cantidad":1,
                        "ClaveUnidad":'',
                        "Unidad": '',
                        "Descripcion":'',
                        "ValorUnitario":'',
                        "Importe":'',
                        "Descuento":''
                            },
                    "traslado":[],
                    "retencion":[]
                };

const itemSelected=ref(JSON.parse(JSON.stringify(conceptoBlank)))

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: 'Esperamos que nos tenga en cuenta para futuros proyectos independientes. ¡Gracias!',
      paymentMethod: null,
    })

    const updateItemForm = (index, val) => {
      const { costo, qty, descripcion,nombre } = val
      invoiceData.value.items[index].costo = costo
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].descripcion = descripcion
      invoiceData.value.items[index].nombre = nombre
    }
  const setSelectedItem = (item)=>{
    //=item;
    //itemSelected.value.qty=item.qty || 1;
    if(item.aplicaIva){
      let impuesto={
                        "Base": item.importe,
                        "Impuesto": "002",
                        "TipoFactor": "Tasa",
                        "TasaOCuota": 0.16,
                        "Importe": item.importe*0.16
                    }
    }
    itemSelected.value={
                    "concepto":{
                        "ClaveProdServ": item.claveSat,
                        "NoIdentificacion": item.nombre,
                        "Cantidad":item.qty || 1,
                        "ClaveUnidad":item.claveUnidad,
                        "Unidad": item.unidad,
                        "Descripcion": item.descripcion,
                        "ValorUnitario": parseFloat(item.costo),
                        "Importe":parseFloat(item.costo) || item.cantidad*1,
                        "Descuento": item.descuento || 0
                            },
                    "traslado":[],
                    "retencion":[]
                }
                if(item.aplicaIva)itemSelected.value.traslado.push({
                        "Base": item.importe,
                        "Impuesto": "002",
                        "TipoFactor": "Tasa",
                        "TasaOCuota": 0.16,
                        "Importe": item.importe*0.16
                    })
                    console.log(itemSelected.value)
  }
    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,

      itemSelected,
      setSelectedItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
